import React from "react";
import Logo from "../logo/logo";
import NavItem from "../nav-item/navItem";
import "./nav.scss";
import BurgerMenu from "../burger-menu/burger-menu";

class Nav extends React.Component{

  constructor(){
    super();

    this.state = {
      minified: false,
      showItems: false,
      items: [],
      navClass: '',
    };
    this.toggleItems = this.toggleItems.bind(this);
  }

  componentDidMount(){
    this.setState({items:this.props.items});

    let prevScroll = 0;
    let threshold = [0,0.1,0.95];
    let thresholdLimit = 0.95;
    const self = this;
    
    if(window && window.innerWidth < 1400 ){
      threshold = [0,0.1,0.70];
      thresholdLimit = 0.70
    }
    
    let observer = new IntersectionObserver(entries => {
      for(const entry of entries){
        if (!entry.isIntersecting) {
          continue;
        }
        const currentScroll = window.scrollY;
        
        //scroll down
        if(entry.intersectionRatio > thresholdLimit && prevScroll < currentScroll){
          self.setState({
            navClass: entry.target.dataset.navClass || '',
          });
          prevScroll = currentScroll;
        }
        
        // scroll up
        if(entry.intersectionRatio > 0.1 && prevScroll > 0 && prevScroll > currentScroll){
          const resetNav = entry.target.dataset.resetNav;

          if(resetNav == 'true'){
            this.setState({
              navClass: '',
            });
          }else{
            this.setState({
              navClass: entry.target.dataset.navClass || '',
            });
          }

          prevScroll = currentScroll;
        }
      }
    }, {
      root: null,
      threshold: threshold
    });

			let targets = document.querySelectorAll('.section');

			if(targets.length > 0){
				for(const target of targets){
					observer.observe(target);
				}
			}
  }

  toggleItems(status){
    this.setState({
      showItems: status
    })
  }

  toggleMinfy(){

  }

  render(){
    return <nav className={`nav ${this.state.navClass}`}>
      <Logo/>
      <BurgerMenu toggleCallback={this.toggleItems}/>
      <div className={'nav--items' + (this.state.showItems ? ' nav--items--active' : '')}>
        {
          this.state.items.map((item,index) => (
            <NavItem key={index} text={item.text} url={item.url} externalLink={item.external} extraClass={item.extraClass} />
          ))
        }
      </div>
    </nav>
  }
}

export default Nav
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "./nav-item.scss";

const NavItem = ({ text,url,externalLink, extraClass }) => (
    externalLink
        ? <a className={`nav-item ${extraClass}`} href={url} target="_blank" rel="noreferrer">{text}</a>
        : <Link 
            activeClassName="nav-item--active"
            className={`nav-item ${extraClass}`} 
            to={url}>{text}</Link>
);

NavItem.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  externalLink: PropTypes.bool,
  extraClass: PropTypes.string,
}

NavItem.defaultProps = {
  text: ``,
  url: null,
  externalLink: false,
  extraClass: '',
}

export default NavItem


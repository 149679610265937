import React from "react";
import SvgBugerMenu from "../../images/hamburger-menu.svg";
import SvgBugerMenuClose from "../../images/close.svg";

import "./burger-menu.scss";

export default class BurgerMenu extends React.Component{
    constructor(props) {
      super(props);
      this.state = {
          open: false
      };
      
      this.toggle = this.toggle.bind(this);
    }

    toggle(event){
        this.setState( (state,props) => {
            return {
              open: state.open === true ? false : true
            }
        });

        this.props.toggleCallback(this.state.open === true ? false : true);
      }

    render(){
        return <div className="toggler--wrapper"> 
            <SvgBugerMenu className={`toggler--icon ${!this.state.open ? 'toggler--icon--visible' : ''}`} onClick={this.toggle} />
            <SvgBugerMenuClose className={`toggler--icon ${this.state.open ? 'toggler--icon--visible' : ''}`}  onClick={this.toggle} />
        </div>
    }
}
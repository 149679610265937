import React from "react";
import { Link } from "gatsby"
import "./copyrights.scss";

class Copyrights extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            cookiesText: props.cookiesText,
            cookiesLinkText: props.cookiesLinkText,
            cookiesDisable: props.cookiesDisable,
            cookiesCopyrights: props.cookiesCopyrights,
            facebookLinkTitle: props.facebookLinkTitle,
            instagramLinkTitle: props.instagramLinkTitle,
            behanceLinkTitle: props.behanceLinkTitle,
        }
    }
    render() {
        return <div className="copyrights">
            <div className="copyrights--autor">
                © Jointoweb 2020 {this.state.cookiesCopyrights}.
                <span>
                    {this.state.cookiesText} <Link to="/polityka-prywatnosci">{this.state.cookiesLinkText}</Link>
                    <a href="https://jakwylaczyccookie.pl/jak-wylaczyc-pliki-cookies/" target="_blank" rel="nofolow noopener noreferrer">
                        {this.state.cookiesDisable}
                    </a>
                </span>
                
            </div>
            <div className="copyrights--social">
                <a href="https://www.behance.net/jointoweb" title={this.state.behanceLinkTitle} target="_blank" rel="noopener noreferrer">Behance</a>
                <a href="https://www.instagram.com/jointoweb/" title={this.state.instagramLinkTitle} target="_blank" rel="noopener noreferrer">Instagram</a>
                <a href="https://www.facebook.com/jointoweb/" title={this.state.facebookLinkTitle} target="_blank" rel="noopener noreferrer">Facebook</a>
            </div>
        </div>;
    }
}

export default Copyrights

import React from "react";
import SvgLogo from "../../images/jtw-logo-new.svg";
import "./logo.scss";
import { Link } from "gatsby";

const Logo = () => {
    return <Link to="/" className="logo">
        <SvgLogo className="logo--image" alt="logo" />
    </Link>
}

export default Logo

import React from "react";
import "./footer.scss";
import Logo from "../logo/logo";
import Copyrights from "../copyrights/copryights";
import AOS from 'aos';
class Footer extends React.Component{
    componentDidMount(){
        this.aos = AOS;
        this.aos.init({
            duration: 500
        });
    }
    
    componentDidUpdate() {
        this.aos.refresh();
    }

    render(){
        return <footer 
            id="footer" 
            className={`footer section ${this.props.backgroundSecondary ? 'footer-secondary' : ''}`}
            data-nav-class="nav--scroll">
            <div className="footer--content" data-aos="fade-up">
                <div className="footer--logo">
                    <Logo/>
                </div>
            </div>
            
            <Copyrights
                cookiesText={this.props.cookiesText}
                cookiesLinkText={this.props.cookiesLinkText}
                cookiesDisable={this.props.cookiesDisable}
                cookiesCopyrights={this.props.cookiesCopyrights}
                facebookLinkTitle={this.props.facebookLinkTitle}
                instagramLinkTitle={this.props.instagramLinkTitle}
                behanceLinkTitle={this.props.behanceLinkTitle}
            />
        </footer>;
    }
}

export default Footer

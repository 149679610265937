import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Nav from "../components/nav/nav";

import "normalize.css";
import "./layout.scss";
import Footer from "./footer/footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
      allYaml{
        nodes {
          nav{
            brief
            offer
            contact,
            portfolio
          },
          footer{
            text,
            tel,
          },
          copyrights{
            cookies{
              text,
              linkText,
              disable,
            },
            rights,
            social{
              facebook{
                linkTitle,
              }
              instagram{
                linkTitle
              },
              behance{
                linkTitle
              }
            }
          }
        }
      }
    }
  `)

  const navItems = [
      {
        text: data.allYaml.nodes[0].nav.offer,
        url: "/uslugi",
        externalLink: false,
      },
      {
        text: 'projekty',
        url: "/projekty",
        externalLink: false,
      },
      {
        text: data.allYaml.nodes[0].nav.portfolio,
        url: "https://behance.net/jointoweb",
        external: true
      },
      {
          text: data.allYaml.nodes[0].nav.brief,
          url: "/brief",
          extraClass: 'nav-item--border'
      },
  ]

  const copyrights = {
      cookiesText: data.allYaml.nodes[0].copyrights.cookies.text,
      cookiesLinkText: data.allYaml.nodes[0].copyrights.cookies.linkText,
      cookiesDisable: data.allYaml.nodes[0].copyrights.cookies.disable,
      cookiesCopyrights: data.allYaml.nodes[0].copyrights.rights,
      facebookLinkTitle: data.allYaml.nodes[0].copyrights.social.facebook.linkTitle,
      instagramLinkTitle: data.allYaml.nodes[0].copyrights.social.instagram.linkTitle,
      behanceLinkTitle: data.allYaml.nodes[0].copyrights.social.behance.linkTitle,
  }

  const url = typeof window !== 'undefined' ? window.location.pathname : '';

  return (
    <>
      <Nav items={navItems} />
      {children}
      <Footer
        text={data.allYaml.nodes[0].footer.text}
        telText={data.allYaml.nodes[0].footer.tel}
        cookiesText={copyrights.cookiesText}
        cookiesLinkText={copyrights.cookiesLinkText}
        cookiesDisable={copyrights.cookiesDisable}
        cookiesCopyrights={copyrights.cookiesCopyrights}
        facebookLinkTitle={copyrights.facebookLinkTitle}
        instagramLinkTitle={copyrights.instagramLinkTitle}
        behanceLinkTitle={copyrights.behanceLinkTitle}
        backgroundSecondary={url === '/uslugi' || url === '/brief'}
      />
      <link href="https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700;900&display=swap" rel="stylesheet" />  
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
